export default {
  appSettings: {
    name: 'DGA - Dirección Verificación del Automotor',
    shortName: 'A. T. V. A',
    version: 'v1.0.21',
    poweredBy: 'Dirección Innovacón, Desarrollo Tecnológico y Robótica',
    state: {
      SAVING_PERIOD: 1000,
    },
    regexps: {
      EMAIL: '[^@ \t\r\n]+@[^@ \t\r\n]+.[^@ \t\r\n]+',
      STRING:
        '^[A-Za-zsàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]+$',
      STRING_NUMERIC:
        '^[0-9-_/A-Za-zsàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð.° ]+$',
      ALPHANUMERIC: '^[a-zA-Z0-9-_]+$',
      NUMERIC_DECIMAL: '^[0-9]+[.]{0,1}[0-9]*$',
      INTEGER_POSITIVE: /^[0-9]+$/,
      PHONE_NUMBER: /^[0-9]+-?[0-9]+$/,
    },
  },
  apis: {
    authApi: {
      // URL: 'https://administracion-api.policiamisiones.gob.ar',
      URL: 'https://stage-administracion-api.policiamisiones.gob.ar',
      AUTHENTICATION_ENDPOINT: '/oauth/token',
      LOGOUT: '/oauth/logout',
      HEADERS: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
    },
    // generalApiUrlBase: 'https://administracion-api.policiamisiones.gob.ar/api',
    generalApiUrlBase: 'https://stage-administracion-api.policiamisiones.gob.ar/api',
    coreApiJefatura: 'https://api.policiamisiones.gob.ar/api',
    default_pagination: {
      current_page: 1,
      from: 1,
      last_page: 1,
      // path: 'http://administracion-api.policiamisiones.gob.ar/api/turns/list-assigned-turns',
      path: 'https://stage-administracion-api.policiamisiones.gob.ar/api/turns/list-assigned-turns',
      per_page: 10,
      to: 4,
      total: 4,
    },
  },
}
